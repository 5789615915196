<template>
  <div class="card card-custom h-100 overflow-auto">
    <div class="card-body">
      <h5 class="mb-3">
        {{ $t("widgets.workflowReporting.workflowReporting") }}
        {{ configJobTime }}
      </h5>
      <div class="row justify-content-between align-items-center mb-3">
        <!--begin::Pagination-->
        <div class="col-auto d-flex align-items-center">
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            class="mr-3 shadow-none"
            value-field="item"
            text-field="name"
            size="sm"
            disabled-field="notEnabled"
            @change="handlePageSizeChange"
          ></b-form-select>
          <span class="text-muted text-no-wrap">
            {{ $t("general.recordsPerPage") }}
          </span>
        </div>
        <!--end:: Pagination-->
      </div>
      <div class="dataTables_wrapper">
        <b-table
          responsive
          :items="items"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell()="data">
            {{
              data.field.key.startsWith("filtered_")
                ? getByClient(data)
                : data.value
            }}
          </template>
          <template #cell(actions)="row">
            <button
              class="btn btn-primary btn-sm"
              :disabled="!row.item.projects.length"
              @click="row.toggleDetails"
            >
              {{
                row.item._showDetails
                  ? $t("widgets.workflowReporting.hideProjects")
                  : $t("widgets.workflowReporting.showProjects")
              }}
            </button>
          </template>
          <template #row-details="data">
            <b-table
              thead-class="invisible"
              table-class="mb-0 mt-n14"
              tbody-tr-class="border-top border-dashed"
              :items="data.item.projects"
              :fields="fields"
              borderless
              :show-empty="true"
              :empty-text="$t('table.noRecords')"
            >
              <template #cell(name)="project">
                <i
                  class="fal fa-arrow-turn-up mr-1 text-dark"
                  style="transform: rotate(90deg)"
                />
                <span>
                  {{
                    project.item.id !== 0
                      ? project.item.name
                      : $t("widgets.workflowReporting.noProject")
                  }}
                </span>
              </template>
              <template #cell()="data">
                {{
                  data.field.key.startsWith("filtered_")
                    ? getByProject(data.item, data.field.key)
                    : data.value
                }}
              </template>
              <template #cell(actions)="projectRow">
                <button
                  class="btn btn-primary btn-sm"
                  :disabled="!projectRow.item.processes.length"
                  @click="projectRow.toggleDetails"
                >
                  {{
                    projectRow.item._showDetails
                      ? $t("widgets.workflowReporting.hideWorkflows")
                      : $t("widgets.workflowReporting.showWorkflows")
                  }}
                </button>
              </template>
              <template #row-details="projectData">
                <b-table
                  thead-class="invisible"
                  table-class="mb-0 mt-n14"
                  tbody-tr-class="border-top border-dashed"
                  :items="projectData.item.processes"
                  :fields="fields"
                  borderless
                  :show-empty="true"
                  :empty-text="$t('table.noRecords')"
                >
                  <template #cell(name)="process">
                    <i
                      class="fal fa-arrow-turn-up mr-1 text-dark"
                      style="transform: rotate(90deg)"
                    />
                    <span>{{ process.item.name }}</span>
                  </template>
                  <template #cell(actions)="processRow">
                    <button
                      class="btn btn-primary btn-sm"
                      @click.left="
                        openReporting(
                          data.item.partner_id,
                          data.item.id,
                          processRow.item.id
                        )
                      "
                      @click.middle="
                        openReporting(
                          data.item.partner_id,
                          data.item.id,
                          processRow.item.id
                        )
                      "
                    >
                      <i class="fal fa-magnifying-glass" />
                      {{ $t("widgets.workflowReporting.openReporting") }}
                    </button>
                  </template>
                </b-table>
              </template>
            </b-table>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span v-if="Object.keys(meta).length" class="text-muted text-no-wrap">
            {{
              $t("general.records", {
                from: meta.from,
                to: meta.to,
                total: meta.total
              })
            }}
          </span>
        </div>
        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="page"
            :total-rows="totalRecords"
            :per-page="pageSize"
            size="md"
            class="pl-0 mb-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import {
  UPDATE_SELECTED_CLIENT_BY_ID,
  UPDATE_SELECTED_PARTNER_BY_ID
} from "@/core/services/store/switchArea.module";

export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // Meta info
      items: [],
      searchTitle: "",
      meta: [],
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      configJobTime: ""
    };
  },
  computed: {
    fields: function () {
      let fields = [
        {
          key: "name",
          label: this.$t("table.level"),
          sortable: true,
          thStyle: "min-width: 185px;"
        },
        {
          key: "filtered_total",
          label: this.$t("table.total"),
          thStyle: "width: 15%;",
          formatter: (value, key, item) => item.iterations_count?.total
        }
      ];
      let statuses = this.config.find(c => c.name === "status").value;
      if (!Array.isArray(statuses)) {
        statuses = [];
      }
      statuses.forEach(status => {
        fields.push({
          key: "filtered_" + status,
          label: this.$t("widgets.workflowReporting." + status),
          thStyle: "width: 15%;",
          formatter: (value, key, item) =>
            item.iterations_count ? item.iterations_count[status] ?? 0 : 0
        });
      });
      fields.push({
        key: "actions",
        label: this.$t("table.actions"),
        sortable: false,
        thStyle: "width: 35%;",
        class: "text-right"
      });
      return fields;
    }
  },
  mounted() {
    this.setMeta();
    this.setItems();
    this.setConfigJobTime();
  },
  methods: {
    setConfigJobTime() {
      switch (this.config.find(c => c.name === "hours").value) {
        case 24:
          this.configJobTime = this.$t("widgets.workflowReporting.last24");
          break;
        case 48:
          this.configJobTime = this.$t("widgets.workflowReporting.last48");
          break;
        case 72:
          this.configJobTime = this.$t("widgets.workflowReporting.last72");
          break;
        default:
          this.configJobTime = "";
      }
    },
    handlePageChange(value) {
      this.page = value;
      this.loadData();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadData();
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) params["page"] = page;
      if (pageSize) params["size"] = pageSize;
      return params;
    },
    setMeta() {
      let meta = this.data;
      this.totalPages = meta.last_page;
      this.totalRecords = meta.total;
      this.page = meta.current_page;
      this.pageSize = meta.per_page;
    },
    getByClient(data) {
      let client = data.item;
      let total = 0;
      if (!client.projects?.length) return 0;
      client.projects.forEach(project => {
        total += this.getByProject(project, data.field.key);
      });
      return total;
    },
    getByProject(project, fieldKey) {
      let status = fieldKey.split("_")[1];
      let total = 0;
      if (!project.processes?.length) return 0;
      project.processes.forEach(process => {
        total += process.iterations_count[status] ?? 0;
      });
      return total;
    },
    async openReporting(partnerId, clientId, processId) {
      await this.$store.dispatch(UPDATE_SELECTED_PARTNER_BY_ID, partnerId);
      await this.$store.dispatch(UPDATE_SELECTED_CLIENT_BY_ID, clientId);
      let me = this;
      setTimeout(function () {
        me.$router.push({
          name: "projectWorkflowsReportingDetails",
          params: { id: processId }
        });
        bus.$emit("updateWidgetsGrid");
      }, 500);
    },
    loadData() {
      const config = {
        page: this.page,
        perPage: this.pageSize
      };
      this.$emit("load-data", config);
    },
    getConfigValue(name) {
      let config = this.config.find(el => el.name === name);
      return config.value;
    },
    setItems() {
      let items = this.data.data;

      if (this.getConfigValue("initially_open") !== "clients") {
        items.forEach(client => {
          client._showDetails = true;
          if (this.getConfigValue("initially_open") === "workflows") {
            client.projects.forEach(project => {
              project._showDetails = true;
            });
          }
        });
      }

      this.items = items;
    }
  }
};
</script>

<style lang="scss">
.b-table {
  td {
    &:last-child {
      padding-right: 0 !important;
    }
  }

  .b-table {
    td {
      &:first-child {
        padding-left: 26px;
      }
    }

    .table {
      tr {
        &:first-child {
          border-top: none !important;
        }
      }

      td {
        &:first-child {
          padding-left: 39px;
        }
      }
    }
  }
}

.b-table-details {
  > td {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
